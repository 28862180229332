export default function SideBar() {
    
    return (
      <div className="dashboardSiderbar">
        <li>
          <a href="/user/dashboard/administrator/beninapp">
            <span class="material-symbols-outlined">dashboard</span>
          </a>
        </li>
        <li>
          <a href="/logout">
            <span class="material-symbols-outlined">logout</span>
          </a>
        </li>
      </div>
    );

}