export default function Footer() {
    
    return (

        <div className="copyright">

            <nav className="footerLinks">

                <li><a href="mailto:info@thebeninapp.com">Send us a mail</a></li>
                <li><a href="/about">About</a></li>
                <li><a href="/login-auth/access">Login</a></li>

            </nav>

            <h1>Copyright &copy; The Benin App 2024</h1>

        </div>

    )

}