export default function Navbar() {
  return (
    <div className="navCtn">
      <div className="navLogo">
        <a href="/home">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/the-benin-app.appspot.com/o/BeninApp_logo-removebg-preview.png?alt=media&token=ffcdac03-d9d7-431d-b065-d4bbf8d5e416"
            alt="The Benin App"
          />
        </a>
      </div>

      <nav className="navLinks">
        <li>
          <a href="/home">Home</a>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/blog">Blog</a>
        </li>
      </nav>
    </div>
  );
}
