import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";
import ScrollReveal from "scrollreveal";

export default function Home() {
  useEffect(() => {
    ScrollReveal().reveal(".reveal__bottom", {
      origin: "bottom",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });

    ScrollReveal().reveal(".reveal__top", {
      origin: "top",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });

    ScrollReveal().reveal(".reveal__left", {
      origin: "left",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });

    ScrollReveal().reveal(".reveal__right", {
      origin: "right",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Home | The Benin App</title>
        <meta
          name="description"
          content="Explore the rich legacy and historical significance of the Benin Kingdom. The Benin App offers in-depth insights into one of Africa's most remarkable civilizations."
        />
        <link rel="canonical" href="https://www.beninapp.com/home" />
        <link rel="og:canonical" href="https://www.beninapp.com/home" />

        <meta property="og:title" content="Home | The Benin App" />
        <meta
          property="og:description"
          content="Explore the rich history and timeless culture of the Benin Kingdom. The Benin App provides a comprehensive journey through the past, preserving this great African legacy."
        />
        <meta property="og:url" content="https://www.beninapp.com/home" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/thebeninapp/image/upload/v1726758920/edoBenin-removebg-preview_cf749i.png"
        />
        <meta name="robots" content="index, follow" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Home | The Benin App" />
        <meta
          name="twitter:description"
          content="Discover the rich history of the Benin Kingdom with The Benin App, your portal to a storied African past."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/thebeninapp/image/upload/v1726758920/edoBenin-removebg-preview_cf749i.png"
        />

        <meta
          name="keywords"
          content="Benin Kingdom, African history, Benin culture, Benin app, Benin heritage, Benin Kingdom history, African legacy, ancient civilizations"
        />
        <meta
          name="og:keywords"
          content="Benin Kingdom, African history, Benin app, African legacy, ancient civilizations"
        />

        <meta name="author" content="Ebire Folayemi Michael" />
        <meta name="revised" content="12th of October 2024" />
      </Helmet>

      <Navbar />
      <div className="HomeHeader">
        <div className="HomeHeaderContent">
          <span className="reveal__top">
            Timeless Ancient legacy found in <text>The Benin App.</text>
          </span>
          <h1 className="reveal__left">
            Discover the rich tapestry of historical events that shaped the
            Benin Kingdom.
          </h1>
          <p className="reveal__right">
            The Benin App offers a comprehensive exploration of one of Africa's
            most remarkable civilizations, ensuring that its legacy lives on for
            future generations.
          </p>

          <div className="HomeHeaderFlex reveal__bottom">
            <a href="#">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/the-benin-app.appspot.com/o/app-store-google-4d63c31a3e.svg?alt=media&token=b1f97e4b-1f9a-4726-8079-2647a2e0ab70"
                alt="The Benin App"
              />
            </a>

            <a href="#">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/the-benin-app.appspot.com/o/app-store-apple-f1f919205b.svg?alt=media&token=b9f7a48c-0a76-4e68-bc14-8dc5a343068e"
                alt="The Benin App"
              />
            </a>
          </div>
        </div>

        <div className="HomeHeaderImg">
          <img
            className="reveal__right"
            src="https://firebasestorage.googleapis.com/v0/b/the-benin-app.appspot.com/o/application.png?alt=media&token=39da7749-2244-4884-912a-17ea538147af"
            alt="The Benin App"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
