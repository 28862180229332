import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";
import ScrollReveal from "scrollreveal";
import axios from "axios";

export default function Blog() {
  const [formData, setFormData] = useState({
    title: "",
    author: "",
    content: "",
    image: null,
  });

  const [publications, setPublications] = useState([]); // State to store the list of publications

  // Fetch publications data from the backend
  useEffect(() => {
    const fetchPublications = async () => {
      try {
        const response = await axios.get(
          "https://thebeninapp-website-backend.onrender.com/get-publications"
        );
        setPublications(response.data.publications);
      } catch (error) {
        console.error("Error fetching publications:", error);
      }
    };
    fetchPublications();
  }, []);

  useEffect(() => {
    ScrollReveal().reveal(".reveal__bottom", {
      origin: "bottom",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });

    ScrollReveal().reveal(".reveal__top", {
      origin: "top",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });

    ScrollReveal().reveal(".reveal__left", {
      origin: "left",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });

    ScrollReveal().reveal(".reveal__right", {
      origin: "right",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Blog Page | Contents</title>
        <meta
          name="description"
          content="Explore the rich legacy and historical significance of the Benin Kingdom. The Benin App offers in-depth insights into one of Africa's most remarkable civilizations."
        />
        <link rel="canonical" href="https://www.beninapp.com/home" />
        <meta property="og:title" content="Home | The Benin App" />
        <meta
          property="og:description"
          content="Explore the rich history and timeless culture of the Benin Kingdom. The Benin App provides a comprehensive journey through the past, preserving this great African legacy."
        />
        <meta property="og:url" content="https://www.beninapp.com/home" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/thebeninapp/image/upload/v1726758920/edoBenin-removebg-preview_cf749i.png"
        />
        <meta name="robots" content="index, follow" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Home | The Benin App" />
        <meta
          name="twitter:description"
          content="Discover the rich history of the Benin Kingdom with The Benin App, your portal to a storied African past."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/thebeninapp/image/upload/v1726758920/edoBenin-removebg-preview_cf749i.png"
        />

        <meta
          name="keywords"
          content="Benin Kingdom, African history, Benin culture, Benin app, Benin heritage, Benin Kingdom history, African legacy, ancient civilizations"
        />
      </Helmet>

      <Navbar />
      <div className="BlogHeaderContent">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/the-benin-app.appspot.com/o/10016587_27264.jpg?alt=media&token=b939e2e3-7b39-41b7-af3e-ce10c6f34b06"
          alt="The Benin App"
        />

        <div className="BlogHeaderContentOverlay">
          <h2>The Benin Kingdom Blog</h2>
          <p>
            Explore in-depth discussions, research, and stories that bring the
            heritage of the Benin Kingdom to life.
          </p>
        </div>
      </div>

      <div className="BlogGridContainer">
        <div className="BlogGrid">
          {publications.map((publication) => (
            <a
              href={`/blog/${publication.id}`} // Assuming each blog post has its own page
              className="BlogGridBox"
              key={publication.id}
            >
              <img
                src={publication.image_url || "/default-image.jpg"} // Fallback image if `image_url` is not available
                alt={publication.title}
              />

              <div className="BlogGridBoxContent">
                <a href={`/blog/${publication.id}`}>{publication.title}</a>
                <p
                  dangerouslySetInnerHTML={{
                    __html: publication.content.substring(0, 150) + "...",
                  }}
                />
              </div>
            </a>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
}
