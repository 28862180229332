import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "axios";

export default function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    console.log("Username:", username); // Log the username
    console.log("Password:", password); // Log the password

    try {
      const response = await axios.post(
        "https://thebeninapp-website-backend.onrender.com/login-authentication/administrator",
        {
          username, // Ensure this matches the hardcoded username
          password, // Ensure this matches the hardcoded password
        }
      );

      if (response.data.message) {
        setMessage(response.data.message);
        console.log("User  data:", response.data);
        // Redirect to the dashboard
        navigate("/user/dashboard/administrator/beninapp");
      }
    } catch (error) {
      console.error("Login error:", error.message); // Log the error message
      setMessage(error.response?.data.error || "An error occurred");
    }
  };

  return (
    <>
      <Navbar />
      <div className="ctn height">
        <img
          src="https://res.cloudinary.com/thebeninapp/image/upload/v1726768568/394_k0y8np.jpg"
          alt="The Benin App"
        />
        <div className="form hover_background">
          <form className="glassyBg" onSubmit={handleSubmit}>
            <div className="formHeader">
              <h2>Login Form</h2>
              <p>
                Access restricted, you need to login to access this account.
              </p>
            </div>

            <input
              type="text"
              placeholder="Enter your Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Type in your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Login Account</button>

            {message && <p className="spanTextNotification">{message}</p>}

            <div className="flexCtn">
              <span className="spanText">
                Need help accessing your account? Get in touch with the
                Administrator.
              </span>
              <a className="clickLink" href="mailto:info@quinndaisies.com">
                Click here
              </a>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
