import Footer from '../components/Footer'
import SideBar from '../components/SideBar'

export default function Users() {
    return (
        <>
        
            <div className='dashboardCtn'>

                <SideBar />

                <div className='dashboardContent'>
                    
                    <div className='dashboardContentHeader'>
                        <h2>Dashboard</h2>
                        <p>User dashboard Information</p>
                    </div>

                    <div className='dashboardList'>

                        <div className='box'>

                            <div className='boxImage'>
                                <img src="https://res.cloudinary.com/thebeninapp/image/upload/v1726758920/edoBenin-removebg-preview_cf749i.png" alt="The Benin App"/>
                            </div>

                            <div className='boxContent'>
                                <h2>Aruan of Udo, The Giant of Benin Kingdom.</h2>
                                <p>This story is a well known story in Benin and has been retold to generations of kids by parents for ages. Benin Kingdom is located in Nigeria, West Africa and is said to be the second largest kingdom after the British kingdom. it is established that when the Europeans first arrived the shores of Benin they met a great civilization operating with an exiting and effective system of government led by the King or Oba.</p>

                                <div className='boxContentDate'>
                                    <h3>The Benin App</h3>
                                    <p>25th of September 2024</p>
                                </div>

                            </div>

                            <div className='btnFlexCtn'>
                                <a className='iconFont' href='/user-dashboard'><span class="material-symbols-outlined iconFont">edit</span></a>
                                <a className='iconFont' href='/user-dashboard'><span class="material-symbols-outlined iconFont">delete</span></a>
                            </div>

                        </div>

                        <div className='box'>

                            <div className='boxImage'>
                                <img src="https://res.cloudinary.com/thebeninapp/image/upload/v1726758920/edoBenin-removebg-preview_cf749i.png" alt="The Benin App"/>
                            </div>

                            <div className='boxContent'>
                                <h2>Aruan of Udo, The Giant of Benin Kingdom.</h2>
                                <p>This story is a well known story in Benin and has been retold to generations of kids by parents for ages. Benin Kingdom is located in Nigeria, West Africa and is said to be the second largest kingdom after the British kingdom. it is established that when the Europeans first arrived the shores of Benin they met a great civilization operating with an exiting and effective system of government led by the King or Oba.</p>

                                <div className='boxContentDate'>
                                    <h3>The Benin App</h3>
                                    <p>25th of September 2024</p>
                                </div>

                            </div>

                            <div className='btnFlexCtn'>
                                <a className='iconFont' href='/user-dashboard'><span class="material-symbols-outlined iconFont">edit</span></a>
                                <a className='iconFont' href='/user-dashboard'><span class="material-symbols-outlined iconFont">delete</span></a>
                            </div>

                        </div>

                        <div className='box'>

                            <div className='boxImage'>
                                <img src="https://res.cloudinary.com/thebeninapp/image/upload/v1726758920/edoBenin-removebg-preview_cf749i.png" alt="The Benin App"/>
                            </div>

                            <div className='boxContent'>
                                <h2>Aruan of Udo, The Giant of Benin Kingdom.</h2>
                                <p>This story is a well known story in Benin and has been retold to generations of kids by parents for ages. Benin Kingdom is located in Nigeria, West Africa and is said to be the second largest kingdom after the British kingdom. it is established that when the Europeans first arrived the shores of Benin they met a great civilization operating with an exiting and effective system of government led by the King or Oba.</p>

                                <div className='boxContentDate'>
                                    <h3>The Benin App</h3>
                                    <p>25th of September 2024</p>
                                </div>

                            </div>

                            <div className='btnFlexCtn'>
                                <a className='iconFont' href='/user-dashboard'><span class="material-symbols-outlined iconFont">edit</span></a>
                                <a className='iconFont' href='/user-dashboard'><span class="material-symbols-outlined iconFont">delete</span></a>
                            </div>

                        </div>

                    </div>
                    
                </div>

                <div className='dashboardFormCtn'>

                        <form>

                            <div className='dashboardFormCtnTitle'>
                                <h3>Create Content</h3>
                                <p>This form allows you to craft engaging and optimized content that meets the needs of your audience.</p>
                            </div>

                            <input className='formBackground' type='' name='' placeholder='Content title' />

                            <input className='formBackground' type='' name='' placeholder='Content Author' />

                            <textarea className='formBackgroundTextarea' placeholder='Content goes here'></textarea>

                            <button type='submit' name='submit'>Create Content</button>
                        </form>

                    </div>

            </div>

            <Footer/>

        </>
    )
}