import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet-async";
import ScrollReveal from "scrollreveal";

export default function About() {
  useEffect(() => {
    ScrollReveal().reveal(".reveal__bottom", {
      origin: "bottom",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });

    ScrollReveal().reveal(".reveal__top", {
      origin: "top",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });

    ScrollReveal().reveal(".reveal__left", {
      origin: "left",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });

    ScrollReveal().reveal(".reveal__right", {
      origin: "right",
      distance: "100px",
      duration: 1000,
      reset: false,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>About | The Benin App</title>
        <meta
          name="description"
          content="Learn more about The Benin App, a digital platform dedicated to showcasing the history, culture, and heritage of the Benin Kingdom. Explore the vision behind the app and the team preserving this African legacy."
        />
        <link rel="canonical" href="https://www.beninapp.com/about" />
        <link rel="og:canonical" href="https://www.beninapp.com/about" />

        <meta property="og:title" content="About | The Benin App" />
        <meta
          property="og:description"
          content="Discover the story behind The Benin App, a unique platform created to celebrate and preserve the rich history of the Benin Kingdom. Learn about the team and the mission to keep this legacy alive."
        />
        <meta property="og:url" content="https://www.beninapp.com/about" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/thebeninapp/image/upload/v1726758920/edoBenin-removebg-preview_cf749i.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About | The Benin App" />
        <meta
          name="twitter:description"
          content="Learn about The Benin App and its mission to preserve the heritage of the Benin Kingdom for future generations."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/thebeninapp/image/upload/v1726758920/edoBenin-removebg-preview_cf749i.png"
        />

        <meta
          name="keywords"
          content="Benin Kingdom, About Benin App, Benin heritage, African history, Benin culture, Benin Kingdom history, African legacy, about the Benin App, cultural preservation"
        />
        <meta
          name="og:keywords"
          content="Benin Kingdom, About Benin App, African history, cultural preservation, African legacy"
        />

        <meta name="author" content="Ebire Folayemi Michael" />
        <meta name="revised" content="12th of October 2024" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <Navbar />
      <div className="AboutHeader">
        <div className="AboutHeaderContent">
          <span className="reveal__top">
            About <text>The Benin App.</text>
          </span>
          <h2 className="reveal__right">Timeless legacy of the ancient Benin empire.</h2>
          <p className="reveal__left">
            Delve into a world of culture, tradition, and power as you explore
            significant milestones and remarkable achievements of this ancient
            civilization.
          </p>
        </div>
      </div>

      <div className="AboutSection">
        <div className="SectionHeader">
          <h3 className="reveal__right">Why The BeninApp</h3>
          <p className="reveal__left">
            The rich tapestry of historical events that shaped the Benin
            Kingdom.
          </p>
        </div>

        <div className="SectionGrid">
          <div className="SectionGridContentBox">
            <span>01.</span>
            <div className="SectionContent">
              <h4>Comprehensive Historical Insights</h4>
              <p>
                Explore an extensive collection of stories, events, and key
                figures that shaped the Benin Kingdom, offering unparalleled
                depth into its cultural and political history.
              </p>
            </div>
          </div>

          <div className="SectionGridContentBox">
            <span>02.</span>
            <div className="SectionContent">
              <h4>Interactive Learning Experience:</h4>
              <p>
                Engage with interactive content that brings history to life
                through visuals, timelines, and immersive storytelling, making
                learning enjoyable for all ages.
              </p>
            </div>
          </div>

          <div className="SectionGridContentBox">
            <span>03.</span>
            <div className="SectionContent">
              <h4>Preserving a Rich Cultural Heritage:</h4>
              <p>
                The Benin App serves as a digital archive, ensuring that the
                customs, art, and achievements of the Benin Kingdom are
                accessible and preserved for future generations.
              </p>
            </div>
          </div>

          <div className="SectionGridContentBox">
            <span>04.</span>
            <div className="SectionContent">
              <h4>Convenient Access Anytime, Anywhere:</h4>
              <p>
                Whether you're a student, researcher, or history enthusiast, The
                Benin App allows you to explore the kingdom’s heritage from the
                convenience of your mobile device, wherever you are.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
