import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { auth, createUserWithEmailAndPassword } from "../firebaseConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function SignupPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    if (password !== confirmPassword) {
      setMessage("Passwords do not match!");
      return;
    }

    try {
      // Create user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Obtain ID Token from Firebase
      const idToken = await user.getIdToken();

      // Send user data to the Flask backend
      try {
        const response = await axios.post(
          "http://localhost:5000/create-user",
          {
            name: "Sample Name",
            email: user.email,
            password: password, // Hash the password for security in the backend
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        console.log(response.data);
        setMessage(`Account created successfully. Welcome, ${user.email}`);

        // Redirect to login page
        navigate("/login-auth/access");
      } catch (error) {
        console.error("Error storing user:", error);
        setMessage(error.response?.data.error || "Error storing user data.");
      }
    } catch (error) {
      console.error("Signup error:", error);

      // Check if the error is due to an email already in use
      if (error.code === "auth/email-already-in-use") {
        setMessage(
          "This email is already in use. Please use a different email."
        );
      } else {
        setMessage(error.message || "An error occurred");
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="ctn height">
        <img
          src="https://res.cloudinary.com/thebeninapp/image/upload/v1726768568/394_k0y8np.jpg"
          alt="The Benin App"
        />
        <div className="form hover_background">
          <form className="glassyBg" onSubmit={handleSubmit}>
            <div className="formHeader">
              <h2>Signup Form</h2>
              <p>Create an account to get started.</p>
            </div>
            <input
              type="email"
              placeholder="Enter your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Type in your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Confirm your Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button type="submit">Create Account</button>
            {message && <p className="spanTextNotification">{message}</p>}
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
