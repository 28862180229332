import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";
import NoPage from "./pages/NoPage";
import "./style/style.css";
import "./style/app.css";
import Users from "./pages/Users";
import SignupPage from "./pages/SignupPage";
import { HelmetProvider } from "react-helmet-async";
import Blog from "./pages/Blog";

export default function App() {
  return (
    <div>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/login-auth/access" element={<LoginPage />} />
            <Route path="/signup-auth/access" element={<SignupPage />} />
            <Route
              path="/user/dashboard/administrator/beninapp"
              element={<Dashboard />}
            />
            <Route path="/users" element={<Users />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}
