import React, { useState, useEffect } from "react";
import axios from "axios";
import SideBar from "../components/SideBar";
import { Helmet } from "react-helmet-async";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Dashboard() {
  const [formData, setFormData] = useState({
    title: "",
    author: "",
    content: "",
    image: null,
  });

  const [publications, setPublications] = useState([]); // State to store the list of publications

  // Fetch publications data from the backend
  useEffect(() => {
    const fetchPublications = async () => {
      try {
        const response = await axios.get(
          "https://thebeninapp-website-backend.onrender.com/get-publications"
        );
        setPublications(response.data.publications);
      } catch (error) {
        console.error("Error fetching publications:", error);
      }
    };
    fetchPublications();
  }, []);

  useEffect(() => {
    const resizeObserverLoopErrSilenced = (err) => {
      if (
        err.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        return;
      }
      console.error(err);
    };
    window.addEventListener("error", resizeObserverLoopErrSilenced);
    return () =>
      window.removeEventListener("error", resizeObserverLoopErrSilenced);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData({ ...formData, content: data });
  };

  const handleEdit = (id) => {
    const publicationToEdit = publications.find((pub) => pub.id === id);
    if (publicationToEdit) {
      setFormData({
        title: publicationToEdit.title,
        author: publicationToEdit.author,
        content: publicationToEdit.content,
        image: null, // Keep the image empty or provide a way to retain the previous image
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://thebeninapp-website-backend.onrender.com/delete-publication/${id}`);
      setPublications(publications.filter((pub) => pub.id !== id));
    } catch (error) {
      console.error("Error deleting publication:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("title", formData.title);
    data.append("author", formData.author);
    data.append("content", formData.content);
    data.append("image", formData.image);

    try {
      const response = await axios.post(
        "https://thebeninapp-website-backend.onrender.com/create-publication",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data.message);

      // Fetch the updated list of publications after a new one is added
      const updatedPublications = await axios.get(
        "https://thebeninapp-website-backend.onrender.com/get-publications"
      );
      setPublications(updatedPublications.data.publications);
    } catch (error) {
      console.error("Error creating publication:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | The Benin App</title>
        <meta
          name="description"
          content="Welcome to The Benin App Dashboard, your central hub for managing and exploring content related to the history, culture, and legacy of the Benin Kingdom. Keep track of your activities, updates, and insights into the rich heritage of Benin."
        />
        <link rel="canonical" href="https://www.beninapp.com/dashboard" />
        <link rel="og:canonical" href="https://www.beninapp.com/dashboard" />

        <meta property="og:title" content="Dashboard | The Benin App" />
        <meta
          property="og:description"
          content="Manage and explore everything related to the history and culture of the Benin Kingdom on The Benin App Dashboard. Stay updated and engage with the preservation of this African legacy."
        />
        <meta property="og:url" content="https://www.beninapp.com/dashboard" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/thebeninapp/image/upload/v1726758920/dashboard-preview.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Dashboard | The Benin App" />
        <meta
          name="twitter:description"
          content="Explore and manage content related to the Benin Kingdom's history and culture through The Benin App Dashboard."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/thebeninapp/image/upload/v1726758920/dashboard-preview.png"
        />

        <meta
          name="keywords"
          content="Benin Kingdom, Benin App Dashboard, Benin culture, African heritage, Benin history, manage Benin content, cultural preservation, dashboard"
        />
        <meta
          name="og:keywords"
          content="Benin Kingdom, Dashboard, Benin heritage, African history, manage content, cultural preservation"
        />

        <meta name="author" content="Ebire Folayemi Michael" />
        <meta name="revised" content="12th of October 2024" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="DashboardCtnView">
        <h2>
          Hello, Please access this page from you Laptop to get better
          performance
        </h2>
      </div>

      <div className="dashboardCtn">
        <SideBar />
        <div className="dashboardContent">
          <div className="dashboardContentHeader">
            <h2>Dashboard</h2>
            <p>User dashboard Information</p>
          </div>

          <div className="dashboardList">
            {publications.map((publication) => (
              <div className="box" key={publication.id}>
                <div className="boxImage">
                  <img src={publication.image_url} alt={publication.title} />
                </div>
                <div className="boxContent">
                  <h2>{publication.title}</h2>
                  <p
                    dangerouslySetInnerHTML={{ __html: publication.content }}
                  ></p>
                  <div className="boxContentDate">
                    <h3>{publication.author}</h3>
                    <p>{publication.date}</p>
                  </div>
                </div>
                <div className="btnFlexCtn">
                  <button
                    onClick={() => handleEdit(publication.id)}
                    className="iconFont"
                  >
                    <span className="material-symbols-outlined iconFont">
                      edit
                    </span>
                  </button>

                  <button
                    onClick={() => handleDelete(publication.id)}
                    className="iconFont"
                  >
                    <span className="material-symbols-outlined iconFont">
                      delete
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="dashboardFormCtn">
          <form onSubmit={handleSubmit}>
            <div className="dashboardFormCtnTitle">
              <p>
                This form allows you to craft engaging and optimized content
                that meets the needs of your audience.
              </p>
            </div>

            <input
              className="formBackground"
              type="text"
              name="title"
              placeholder="Content title"
              value={formData.title}
              onChange={handleChange}
              required
            />

            <input
              className="formBackground"
              type="text"
              name="author"
              placeholder="Content Author"
              value={formData.author}
              onChange={handleChange}
              required
            />

            <input
              className="formBackgroundImg"
              type="file"
              name="image"
              onChange={handleFileChange}
              required
            />

            {/* CKEditor integration */}
            <CKEditor
              editor={ClassicEditor}
              data={formData.content}
              onChange={handleEditorChange}
              required
            />

            <button type="submit" name="submit">
              Create Content
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
