// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC5uORTVACRvmgp5Q6Y1BYYmLkSRewsUPM",
    authDomain: "the-benin-app.firebaseapp.com",
    projectId: "the-benin-app",
    storageBucket: "the-benin-app.appspot.com",
    messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
    appId: "1:944919991451:android:e4e7cccbcc4e950448e23e",
    databaseURL: "https://the-benin-app.firebaseio.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

// Export the auth and Firestore instances, along with the necessary authentication functions
export { auth, firestore, createUserWithEmailAndPassword, signInWithEmailAndPassword };
